/* demo only */

.flex {
    display: flex;
}

.align-center {
    justify-content: center;
}

.align-vert,
.align-vert.align-center {
    align-items: center;
}


/* end demo */

.modal {
    display: none;
}

.modal--show,
.modal--hide {
    display: flex;
}


/* classes fired by js for animation control */


/* This is on the wrapper for the whole modal */

.modal--align {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 999;
}

.modal__container {
    position: relative;
    top: 45% !important;
    width: 100%;
    /*max-width: 1000px; */
    /* max-height: 800px;*/
    padding: 20px;
    margin: 12px;
    background: #fff;
}

.modal__text {
    position: relative;
    width: 100%;
    max-width: 1500px;
    /*max-height: 800px;*/
    padding-left: 100px;
    padding-right: 100px;
    /*margin: 12px;*/
    /*background: #fff; */
}


/* The .modal__close class is used in js but is modified '--x' here */

.modal__close--x {
    font-size: 30px;
    /* this is only because we use unicode for the X in this case */
    position: absolute;
    top: 3px;
    right: 10px;
}


/* As there is no href to avoid the hash being added to the URL when clicked we add a pointer */


/* This 'x' is hidden from screen readers as there is an accessible close button in the modal */

.modal__close--x:hover {
    cursor: pointer;
}


/* Animations */


/* Open */

.modal.modal--show {
    animation: modal-open 0.3s;
}

@keyframes modal-open {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


/* Close */

.modal.modal--hide {
    animation: modal-close 0.3s;
}

@keyframes modal-close {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}